import LoopPin from './loop-pin.svg'
import LoopPinSelected from './loop-pin-blue.svg'
import ProfileIcon from './profile.svg'
import AccountIcon from './account.svg'
import SignOutIcon from './signout.svg'
import LinePayIcon from './linePay.svg'
import PayPalIcon from './payPal.svg'
import BankIcon from './bankIcon.svg'
import Bottle from './bottle.svg'

const icons = {
  AccountIcon,
  BankIcon,
  Bottle,
  LinePayIcon,
  LoopPin,
  LoopPinSelected,
  PayPalIcon,
  ProfileIcon,
  SignOutIcon,
}

export default icons
