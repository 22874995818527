import Australia from './Australia-badge.svg'
import AustraliaFlag from './Australia-flag.png'
import Canada from './Canada-badge.svg'
import CanadaFlag from './Canada-flag.png'
import France from './France-badge.svg'
import FranceFlag from './France-flag.png'
import Japan from './Japan-badge.svg'
import JapanFlag from './Japan-flag.png'
import UK from './UK-badge.svg'
import UKFlag from './UK-flag.png'
import US from './US-badge.svg'
import USFlag from './US-flag.png'

const countryImages = {
  Australia: { flag: AustraliaFlag, img: Australia },
  Canada: { flag: CanadaFlag, img: Canada },
  France: { flag: FranceFlag, img: France },
  Japan: { flag: JapanFlag, img: Japan },
  UK: { flag: UKFlag, img: UK },
  US: { flag: USFlag, img: US },
}

export default countryImages
