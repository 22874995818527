import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, notification, Row, Typography } from 'antd'
import { useCookies } from 'react-cookie'
import { isEmpty } from 'lodash'
import apis from 'webapp/api'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { CountryChange } from 'webapp/components'
import { ForgotPasswordModal } from './ForgotPasswordModal'

import { user } from 'webapp/App'

const { Text, Title } = Typography

export const refetchUser = (token , removeCookie) => {
  if (!token) return false

  user.isLoading.value = true
  apis.webappInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`

  apis.webappInstance.get('/api/v2/user')
    .then(({ data }) => {
      user.me.value = data.data

      user.withdraw.value = null
    })
    .catch((error) => {
      removeCookie('token')
      removeCookie('refreshToken')

      user.token.value = null
      user.refreshToken.value = null
      user.me.value = null
      user.bankData.value = null
    })
    .finally((error) => {
      user.isLoading.value = false
    })
}

export const Login = () => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(false)
  const usernameRef = useRef('')
  const passwordRef = useRef('')

  const [, setCookie, removeCookie] = useCookies(['token', 'refreshToken'])

  const [isModalVisible, setIsModalVisible] = useState({})

  useEffect(() => {
    if (user.me.value) {
      navigate('/')
    }
  }, [user, navigate])

  const handleOpenModal = (modalName) => {
    setIsModalVisible({
      [modalName]: true,
    })
  }

  const handleLogin = (e) => {
    e.preventDefault()

    const country_code = localStorage.getItem('country')?.replace('gb', 'uk')

    const email = usernameRef.current.input.value
    const password = passwordRef.current.input.value

    if (!isEmpty(email) && !isEmpty(password)) {
      setIsLoading(true)

      apis.webappInstance
        .post('/api/v2/oauth/token',
          {
            client_id: 'webapp',
            country_code,
            email,
            grant_type: 'password',
            password,
          },
        )
        .then(({ data }) => {
          setCookie('token', data.access_token, { maxAge: data.expires_in })
          setCookie('refreshToken', data.refresh_token, { maxAge: data.expires_in })

          user.token.value = data.access_token
          user.refreshToken.value = data.refresh_token

          refetchUser(data.access_token, removeCookie)
        })
        .catch((error) => {
          notification.error({
            description: <Trans>loginFailed</Trans>,
            message: <Trans>loginFailedMessage</Trans>,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Row>
      <Col className="loginPage" lg={{ offset: 8, span: 8 }} xs={24}>
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <Title level={2}>
            <Trans>login</Trans>
          </Title>
        </Col>
        <Form className="loginForm" form={form} layout="vertical">
          <Form.Item>
            <Text className="d-block text-center" strong><Trans>loopLocation</Trans></Text>
            <Text className="d-block text-center"><Trans>loopLocationSub</Trans></Text>
            <CountryChange defaultValue={i18n.language} />
          </Form.Item>

          <Form.Item
            label={<Trans>email</Trans>}
            name="email"
            rules={[{
              message: <Trans>enterValidEmail</Trans>,
              required: true,
            }]}
          >
            <Input placeholder={t('email')} ref={usernameRef} type="email" value={usernameRef} />
          </Form.Item>

          <Form.Item
            label={<Trans>password</Trans>}
            name="password"
            rules={[{
              message: <Trans>enterValidPsw</Trans>,
              required: true,
            }]}
          >
            <Input.Password onPressEnter={handleLogin} placeholder={t('password')} ref={passwordRef} value={passwordRef} />
          </Form.Item>

          <Button
            className="d-flex align-item-center w-100 primary-button justify-content-center"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleLogin}
            type="primary"
          >
            <Trans>login</Trans>
          </Button>

          <Row className="mt-3 d-flex flex-column align-items-center">
            <Col className="w-100">
              <Link className="d-flex align-item-center w-100 secondary-button justify-content-center mb-2" to="/signup">
                <Trans>signUp</Trans>
              </Link>
            </Col>
            <Col className="mt-2">
              <Button onClick={() => handleOpenModal('forgotPassword')} type="link"><Trans>forgotPsw</Trans></Button>
            </Col>
          </Row>
        </Form>
      </Col>

      {isModalVisible.forgotPassword && (
        <ForgotPasswordModal
          hideModal={() => setIsModalVisible({ forgotPassword: false })}
          username={usernameRef.current.input.value || ''}
        />
      )}
    </Row>
  )
}

export default Login
