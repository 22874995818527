import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const Counter = ({ numberValue, fullNumber }) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (numberValue > 0) {
      const incrementCount = (prevCount) => {
        const nextCount = (prevCount + 1) % 10
        if (nextCount === numberValue) {
          clearInterval(interval)
        }
        return nextCount
      }

      const interval = setInterval(() => setCount(incrementCount), 50)
      return () => clearInterval(interval)
    }
  }, [numberValue])

  return (
    <div className="counter">
      <div className={`digits ${fullNumber === 0 ? 'targetNull' : ''}`} style={{ transform: `translateY(-${count * 50}px)` }}>
        {React.Children.toArray(
          Array.from({ length: 10 }).map((_, i) => (
            <div className="digit">{i}</div>
          ))
        )}
      </div>
    </div>
  )
}

Counter.propTypes = {
  fullNumber: PropTypes.number,
  numberValue: PropTypes.number,
}

const SlotMachine = ({ target }) => {
  const [hundreds, tens, ones] = String(target).padStart(3, '0').split('').map(Number)

  return (
    <div className="slot-machine">
      <Counter fullNumber={target} numberValue={hundreds} />
      <Counter fullNumber={target} numberValue={tens} />
      <Counter fullNumber={target} numberValue={ones} />
    </div>
  )
}

SlotMachine.propTypes = {
  target: PropTypes.number,
}

export default SlotMachine
