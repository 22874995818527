import React from 'react'
import { useCookies } from 'react-cookie'
import { Trans, useTranslation } from 'react-i18next'
import Image from 'react-bootstrap/Image'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../images/loop-logo.svg'
import icons from 'webapp/images/icons'

import { user } from 'webapp/App'

const Navigation = () => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [isActive, setIsActive] = React.useState(false)
  const [, , removeCookie] = useCookies(['token', 'refreshToken'])
  const country = i18n.language

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const items = [
    {
      key: 'product',
      label: t('products'),
      to: '/products',
    },
    {
      key: 'faq',
      label:  t('faq'),
      to: '/faq',
    },
    {
      key: 'howItWorks',
      label:  t('howItWorks'),
      to:'/how-it-works',
    },
    {
      key: 'aboutUs',
      label:  t('aboutUs'),
      target: '_blank',
      to:'https://exploreloop.com/',
    },
    {
      key: 'loopApp',
      label:  t('loopApp'),
      target: '_blank',
      to: isMobile ? 'https://loopapplication.page.link/promotion' : `https://loopmobileapp.com/${country || 'en-US'}`,
    },
    {
      key: 'contactUs',
      label: t('contactUs'),
      to: '/contact-us',
    },
    {
      key: 'shopReturn',
      label: t('shopReturn'),
      to: '/shop-and-return',
    },
  ]

  const handleSignOut = () => {
    removeCookie('token')
    removeCookie('refreshToken')

    localStorage.clear()

    user.token.value = null
    user.refreshToken.value = null
    user.me.value = null
    user.bankData.value = null

    navigate('/')

    window.location.reload()
  }

  return (
    <Navbar bg="white" expand="lg">
      <Link to="/">
        <Image alt="Loop logo" className="navLogo" src={logo} />
      </Link>

      <button
        aria-controls="basic-navbar-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        className={`navbar-toggler hamburger hamburger--spin ${isActive ? 'is-active' : ''}`}
        data-mdb-target="#basic-navbar-nav"
        data-mdb-toggle="collapse"
        onClick={() => setIsActive(!isActive)}
        type="button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>

      <Navbar.Collapse className={`justify-content-end ${isActive ? 'show' : ''}`} id="basic-navbar-nav">
        <Nav className="mr-auto topMenu">
          {items.map(item => (
            <Link
              className={`nav-link ${location.pathname === item.to ? 'active' : ''}`}
              key={item.key}
              onClick={() => {
                setIsActive(false)
              }}
              target={item.target || '_self'}
              to={item.to}
            >
              {item.icon}
              <span className="ml-2">{item.label}</span>
            </Link>
          ))}
        </Nav>
        <Nav className="mr-auto">
          {user.me.value ? (
            <NavDropdown align="end"
              id="basic-nav-dropdown"
              title={
                <>
                  <svg fill="none" height="21" viewBox="0 0 22 23" width="22" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" d="M20 18.9148C20 13.8692 15.9706 9.77901 11 9.77901C6.02944 9.77901 2 13.8692 2 18.9148V21H20V18.9148Z" fillRule="evenodd" stroke="#000000a6" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.66667" />
                    <path clipRule="evenodd" d="M10.877 9.61313C12.9481 9.61313 14.627 7.90887 14.627 5.80656C14.627 3.70426 12.9481 2 10.877 2C8.80598 2 7.12704 3.70426 7.12704 5.80656C7.12704 7.90887 8.80598 9.61313 10.877 9.61313Z" fillRule="evenodd" stroke="#000000a6" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.66667" />
                  </svg>
                  <span className="user-text"><Trans>user</Trans></span>
                </>}
            >
              <NavDropdown.Item
                as={Link}
                className="nav-link"
                to="/profile"
              >
                <Image alt="profile icon" className="icon-list" src={icons.ProfileIcon} />
                <span className="ml-2">
                  <Trans>profilePage</Trans>
                </span>
              </NavDropdown.Item>

              <NavDropdown.Item
                as={Link}
                className="nav-link"
                to="/account"
              >
                <Image alt="account icon" className="icon-list" src={icons.AccountIcon} />
                <span className="ml-2">
                  <Trans>account</Trans>
                </span>
              </NavDropdown.Item>

              <NavDropdown.Item
                className="nav-link"
                onClick={handleSignOut}
              >
                <Image alt="signout icon" className="icon-list" src={icons.SignOutIcon} />
                <span className="ml-2">
                  <Trans>signOut</Trans>
                </span>
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Link className="nav-link loginMenu" to="/login">
              <Trans>login</Trans>
            </Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation
