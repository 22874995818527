import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { CookiesProvider } from 'react-cookie'
import CountrySelector from './CountrySelector'
import { LanguageProvider } from './modules'
import App from './App'
import './modules/i18n'
import logo from './images/loop-logo.svg'

import './index.scss'

const trackingId = 'G-H93B0TK575'
const oneTrustKey = process.env.REACT_APP_ONETRUST_KEY || ''

const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2)
    return parts.pop().split(';').shift()
}

const Root = () => {
  const forcedLocale = getCookie('initLocale')

  useEffect(() => {
    if (!localStorage.getItem('country') && forcedLocale) {
      localStorage.setItem('country', forcedLocale.split('-')[1].toLowerCase())
      localStorage.setItem('locale', forcedLocale)
    }
  }, [forcedLocale])

  return (
    <BrowserRouter>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>

        <Helmet>
          <script
            src={`https://cdn.cookielaw.org/consent/${oneTrustKey}/OtAutoBlock.js`}
            type="text/javascript"
          >
          </script>

          <script
            charSet="UTF-8"
            data-domain-script={oneTrustKey}
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
          >
          </script>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
          >
          </script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingId}');
          `}
          </script>
        </Helmet>

        <title>Loop deposit web</title>
        <meta content="Manage your balance and request withdrawals" name="description" />
        <meta content="loop, recycling, reusable, sustainable" name="keywords" />
        <meta content="Terra Cycle - Loop" name="author" />
        <meta content="Loop deposit web" property="og:title" />
        <meta content="Manage your balance and request withdrawals" property="og:description" />
        <meta content={logo} property="og:image" />
        <meta content="Loop deposit web" name="twitter:title" />
        <meta content="Manage your balance and request withdrawals" name="twitter:description" />
        <meta content={logo} name="twitter:image" />

        <LanguageProvider>
          {localStorage.getItem('country') || forcedLocale
            ? <App />
            : <CountrySelector />
          }
        </LanguageProvider>
      </CookiesProvider>
    </BrowserRouter>
  )
}
export default Root
