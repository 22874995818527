import React from 'react'
import { Trans } from 'react-i18next'
import { CountryChange } from 'webapp/components'
import { user } from 'webapp/App'

const currentYear = new Date().getFullYear()

export const Footer = () => {

  return (
    <footer className="w-full py-3 mt-9">
      {!user.me.value && <CountryChange />}

      <div className="container text-center">
        <p><Trans>companyName</Trans></p>
        <p><button className="ot-sdk-show-settings" id="ot-sdk-btn"><Trans>cookieSettings</Trans></button></p>
        <p>&copy; {currentYear} <Trans>arr</Trans></p>
      </div>
    </footer>
  )
}

export default Footer
