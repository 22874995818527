import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: ['en-US', 'en-GB', 'en-CA', 'fr-FR', 'ja-JP'],
    interpolation: {
      escapeValue: false,
    },
    lng: localStorage.getItem('locale') || 'en-US',
  })

export default i18n
