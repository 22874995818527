import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Button, Flex, Modal, Typography, Form, Input, notification } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { forgotPsw } from './api'

const { Text, Title } = Typography

export const ForgotPasswordModal = ({ hideModal, username }) => {
  const usernameRef = useRef('')
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState({ forgotPassword: true })
  const [backendErrors, setBackendErrors] = useState(null)

  const handleFinish = () => {
    const country = localStorage.getItem('country').replace('gb', 'uk')
    const email = usernameRef.current.input.value

    form
      .validateFields()
      .then(() => {

        forgotPsw(country, email)
          .then(() => {
            setIsModalVisible({forgotPassword: false})
            setIsModalVisible({thankYouPage: true})
            notification.success({
              message: <Trans>recoverySent</Trans>,
            })
          })
          .catch(error => {
            console.error('error', error)
            const errorData = error?.response?.data

            if (errorData && errorData.message) {
              setBackendErrors(errorData.message)
              notification.error({
                message: <Trans>sww</Trans>,
              })
            }
          })
      })
  }

  const handleCancel = () => {
    setIsModalVisible({ thankYouPage: false })
    hideModal()
  }

  const initialValues = {
    email: username,
  }

  return (
    <>
      {isModalVisible.forgotPassword && (

        <Modal
          footer={null}
          onCancel={hideModal}
          open={isModalVisible}
        >
          <Flex align="center" className="forgotPasswordModal" justify="center" vertical={true}>
            <MailOutlined />
            <Title level={4}><Trans>enterEmailAddress</Trans></Title>
            <Text><Trans>sendRecoveryEmail</Trans></Text>
            <Form
              form={form}
              initialValues={initialValues}
              layout="vertical"
              onFinish={handleFinish}
            >
              <Form.Item
                help={backendErrors && backendErrors}
                label={<Trans>onlyEmail</Trans>}
                name="email"
                rules={[
                  { message: <Trans>enterValidEmail</Trans>, required: true },
                  { message: <Trans>emailInputError</Trans>, type: 'email' },
                ]}
                type="email"
                validateStatus={backendErrors && 'error'}
              >
                <Input ref={usernameRef} />
              </Form.Item>
              <Form.Item>
                <Button className="primary-button w-100" htmlType="submit" type="primary">
                  <Trans>sendRecovery</Trans>
                </Button>
              </Form.Item>
            </Form>
          </Flex>
        </Modal>
      )}

      {isModalVisible.thankYouPage && (
        <Modal
          footer={null}
          onCancel={handleCancel}
          open
        >
          <Flex align="center" className="forgotPasswordModal" justify="center" vertical={true}>
            <MailOutlined />
            <Title level={4}><Trans>checkYourInbox</Trans></Title>
            <Text><Trans>linkSentTo</Trans></Text>
            <p>{form.getFieldValue('email')}</p>

            <Button className="secondary-button w-100 mt-4" onClick={() => setIsModalVisible({ forgotPassword: true })}>
              <Trans>mistyped</Trans>
            </Button>
          </Flex>
        </Modal>
      )}
    </>
  )
}

ForgotPasswordModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  username: PropTypes.string,
}

export default ForgotPasswordModal
