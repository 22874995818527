import axios from 'axios'

const strapiInstance = axios.create({
  baseURL: 'https://webapp.loopcms.app/api', // TODO: get from config endpoint
})

strapiInstance.defaults.headers.post['Content-Type'] = 'application/json'
strapiInstance.defaults.headers.common['Authorization'] = `bearer ${process.env.REACT_APP_AUTH_TOKEN}`

const webappInstance = axios.create({
  baseURL: '/', // TODO: /api/v2
})

webappInstance.defaults.headers.post['Content-Type'] = 'application/json'

const apis = {
  strapiInstance,
  webappInstance,
}

export const userApi = {
  createPaymentMethod: ({ fields, paymentMethod }) => apis.webappInstance.post(`/api/v2/payment_methods/${paymentMethod}`, fields),
  getBalance: async () => await webappInstance.get('/api/v2/user/balance'),
  getBankData: async () => await apis.webappInstance.get('/api/v2/payment_methods'),
  getTransactions: async (itemsPerPage = 1000, page = 1, typeFilter, statusFilter, sortOrder) => await webappInstance.get(`/api/v2/merged_transactions?items_per_page=${itemsPerPage}&page=${page}${typeFilter ? `&type=${typeFilter}` : ''}${statusFilter ? `&status=${statusFilter}` : ''}${sortOrder ? `&sort_by=${sortOrder}` : ''}`),
  updateDefaultPaymentMethod: (withdrawType) => apis.webappInstance.put('/api/v2/payment_methods', withdrawType),
  updatePassword: (fields) => webappInstance.post('/api/v2/user/change_password', fields),
  updatePersonalData: async (fields) => await apis.webappInstance.patch('/api/v2/user', fields),
  withdraw: async (fields) => await webappInstance.post('/api/v2/withdrawals', fields),
}

export default apis
