import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { LanguageContext } from '../../'
import apis from 'webapp/api'

const LanguageProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      const { data } = await apis.strapiInstance.get('/translation?locale=all')

      data.data.forEach(({ attributes}) => {
        i18n.addResources(attributes.locale, 'translation', attributes.translations)
      })

      setIsLoading(false)
    }

    fetchData()
  }, [])

  if (isLoading) return false

  return (
    <LanguageContext.Provider value={{}}>
      {children}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LanguageProvider
