import React, { Suspense, lazy } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { signal } from '@preact/signals-react'
import { useCookies } from 'react-cookie'
import { LoadingOutlined } from '@ant-design/icons'
import { Footer, Navigation } from './components'

import { refetchUser } from './pages/login/Login'

const AccountPage = lazy(() => import('./pages/account/Account'))
const ContactUs = lazy(() => import('./pages/contact-us/ContactUs'))
const DsarPage = lazy(() => import('./pages/dsar/Dsar'))
const FaqPage = lazy(() => import('./pages/faq-page/FaqPage'))
const Home = lazy(() => import('./pages/home/Home'))
const HowItWorks = lazy(() => import('./pages/how-it-works/HowItWorks'))
const LoginPage = lazy(() => import('./pages/login/Login'))
const PageNotFound = lazy(() => import('./pages/error/PageNotFound'))
const ProductCatalogPage = lazy(() => import('./pages/product-catalog/ProductCatalog'))
const ProfilePage = lazy(() => import('./pages/profile-page/ProfilePage'))
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy/PrivacyPolicy'))
const ResetPasswordPage = lazy(() => import('./pages/reset-password/ResetPasswordPage'))
const SignUpPage = lazy(() => import('./pages/sign-up/SignUp'))
const ShopAndReturn = lazy(() => import('./pages/shop-and-return/ShopAndReturn'))
const ThankYouPage = lazy(() => import('./pages/thank-you/ThankYouPage'))
const VerifyEmail = lazy(() => import('./pages/verify-email/VerifyEmail'))
const VerificationError = lazy(() => import('./pages/verification-error/VerificationError'))
const WithdrawPage = lazy(() => import('./pages/withdraw/Withdraw'))

export const user = {
  balance: signal(null),
  bankData: signal(null),
  isLoading: signal(false),
  isPageLoading: signal(false),
  me: signal(null),
  refreshToken: signal(null),
  selectedCountry: signal(localStorage.getItem('country') || null),
  token: signal(null),
  withdraw: signal(null),
}

export const WebApp = () => {
  const [cookies, , removeCookie] = useCookies(['initLocale', 'token', 'refreshToken'])
  const location = useLocation()
  const isHeadless = new URLSearchParams(location.search).has('headless')

  if (user.selectedCountry.value && !user.me.value && cookies.token) {
    refetchUser(cookies.token, removeCookie)
  }

  if (!user.me.value && user.isLoading.value) return false

  return (
    <div className="container mx-auto">
      <div className="row">
        <div className="col-12">
          <div className="logo" />
          {!isHeadless && <Navigation />}

          <div className="row">
            <div className="col-12 mb-150">
              <Suspense fallback={<div className="text-center"><LoadingOutlined /></div>}>
                <Routes>
                  <Route element={<Home />} path="/" />
                  <Route element={<ProductCatalogPage isHeadless={isHeadless} />} path="/products" />
                  <Route element={<FaqPage />} path="/faq" />
                  <Route element={<HowItWorks />} path="/how-it-works" />
                  <Route element={<ContactUs />} path="/contact-us" />
                  <Route element={<ShopAndReturn />} path="/shop-and-return" />
                  <Route element={<PrivacyPolicy />} path="/privacy-policy" />
                  <Route element={<ThankYouPage />} path="/thank-you" />
                  <Route element={<ResetPasswordPage />} path="/reset-password" />
                  <Route element={<DsarPage />} path="/dsar" />

                  {user.me.value &&
                    <React.Fragment>
                      <Route element={<ProfilePage />} path="/profile" />
                      <Route element={<AccountPage />} path="/account" />
                      <Route element={<VerifyEmail />} path="/verify-email" />
                      <Route element={<VerificationError />} path="/verification-error" />
                      <Route element={<WithdrawPage />} path="/withdraw" />
                    </React.Fragment>
                  }

                  <Route element={<LoginPage />} path="/login" />
                  <Route element={<SignUpPage />} path="/signup" />
                  <Route element={<PageNotFound />} path="*" />
                </Routes>
              </Suspense>
            </div>
          </div>

          {!isHeadless && <Footer />}
        </div>
      </div>
    </div>
  )
}

export default WebApp
