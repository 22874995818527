import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { countries } from 'webapp/constant'

import { user } from 'webapp/App'

const { Option } = Select

const CountryChange = ({ defaultValue, reload }) => {
  const { i18n, t } = useTranslation()

  const changeLanguage = (selectedLocale) => {
    const country = selectedLocale.toLowerCase().split('-')[1]

    i18n.changeLanguage(selectedLocale)

    localStorage.setItem('country', country)
    localStorage.setItem('locale', selectedLocale)

    user.selectedCountry.value= country

    reload && window.location.reload(false)
  }

  return (
    <Select className="countrySelectorList" name="country" onChange={(selected) => changeLanguage(selected)} placeholder={t('selectCountry')} value={defaultValue || undefined}>
      {React.Children.toArray(countries.map(country =>
        <Option className="list-none" value={country.locale}>
          <img alt={country.name} className="language-selector-image" src={country.flag} width="40px" /> {country.name}
        </Option>
      ))}
    </Select>
  )
}

CountryChange.defaultValue = {
  reload: false,
}

CountryChange.propTypes = {
  defaultValue: PropTypes.string,
  reload: PropTypes.bool,
}

export default CountryChange
