import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Col, Flex, Select, Typography } from 'antd'
import { compact, uniqBy } from 'lodash'
import { countryCoordinates, mapStyles } from 'webapp/constant'
import apis from 'webapp/api'
import icons from 'webapp/images/icons'

const { Option } = Select
const { Text, Title } = Typography

const options = {
  fullscreenControl: false,
  mapTypeControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  styles: mapStyles,
}

const LocationsMap = () => {
  const navigate = useNavigate()
  const mapRef = useRef()
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    id: 'google-map-script',
  })
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const { i18n } = useTranslation()
  const id = query.get('id')
  const zoom = parseInt(query.get('zoom'), 10)
  const [activeMarker, setActiveMarker] = useState(null)
  const [selectedRetailerId, setSelectedRetailerId] = useState(null)
  const [mapPoints, setMapPoints] = useState([])
  const [mapZoom, setMapZoom] = useState(zoom || 2)
  const [mapCenter, setMapCenter] = useState(countryCoordinates[i18n.language])
  const country_code = localStorage.getItem('country').replace('gb', 'uk')

  const mapOnLoad = (map) => {
    mapRef.current = map
    fetchBins()

    !zoom && setMapZoom(4)

    const onNavigatorLocationSucces = ({ coords }) => {
      setMapZoom(14)
      setMapCenter({ lat: coords.latitude, lng: coords.longitude })
    }

    navigator?.geolocation?.getCurrentPosition(onNavigatorLocationSucces, () => {}, options)
  }

  useEffect(() => {
    fetchBins()
  }, [country_code])

  useEffect(() => {
    if (id && mapPoints) {
      const marker = mapPoints.find(point => point.id === id)
      if (marker) {
        setActiveMarker(marker)
        mapRef?.current?.panTo({ lat: marker.lat, lng: marker.lng })
      }
    }
  }, [id, mapPoints])

  useEffect(() => {
    mapOnLoad()
    setMapCenter(countryCoordinates[i18n.language])
    setMapZoom(4)
  }, [country_code])

  const fetchBins = async () => {
    const { data } = await apis.webappInstance.get('/api/v2/bins', {
      params: {
        country_code,
      },
    })

    setMapPoints(data.data)
  }

  const handleMarkerClick = (location) => {
    setActiveMarker(activeMarker === location ? null : location)

    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom()
      setMapZoom(newZoom)
      const queryParams = `?id=${location.id}&zoom=${newZoom}`
      navigate(queryParams)
    }
  }

  const handleRetailerChange = (value) => {
    setSelectedRetailerId(value)
    setActiveMarker(null)
  }

  const retailerList = compact(uniqBy(mapPoints, 'retailer.id').map(({ retailer }) => retailer))

  return isLoaded ? (
    <div className="pickUpLocationMapWrapper">
      <Title level={4}><Trans>locationTitle</Trans></Title>
      <Text className="subtitle"><Trans>locationSubTitle</Trans></Text>

      {retailerList.length > 1 && (
        <Flex className="binTypeFlex" justify="end">
          <Col className="binTypeSelectWrapper">
            <Select className="binTypeSelect" defaultValue="" onChange={handleRetailerChange}>
              <Option value=""><Trans>allBinType</Trans></Option>
              {retailerList.map(({ id, name }) => (
                <Option key={id} value={id}>{name}</Option>
              ))}
            </Select>
          </Col>
        </Flex>
      )}

      <div className="pickUpLocationMap">
        <GoogleMap
          center={mapCenter}
          mapContainerStyle={{ height: '100vh', width: '100%' }}
          onLoad={mapOnLoad}
          options={options}
          zoom={mapZoom}
        >
          {React.Children.toArray(
            mapPoints.filter(({ retailer }) => !selectedRetailerId || (retailer?.id === selectedRetailerId))
              .map(location => (
                <Marker
                  icon={icons.LoopPin}
                  onClick={() => handleMarkerClick(location)}
                  position={{ ...location }}
                />
              ))
          )}

          {activeMarker && (
            <InfoWindow
              onCloseClick={() => setActiveMarker(null)}
              position={{ ...activeMarker }}
            >
              <div className="infoWindow">
                <div>
                  <p>{activeMarker.details}</p>
                </div>
                <div className="route-plan">
                  <Link target="_blank" to={`https://www.google.com/maps/dir/?api=1&destination=${activeMarker.lat},${activeMarker.lng}`}>
                    <Trans>startNavigation</Trans>
                  </Link>
                </div>

                <img alt="Loop pin" className="activePin" src={icons.LoopPinSelected} />
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </div>
  ) :
    null
}

export default LocationsMap
