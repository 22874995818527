import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Button, Image } from 'react-bootstrap'
import Icon, { EditOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import { paymentMethodsDetails } from 'webapp/constant/payments'

import { userApi } from 'webapp/api'
import { user } from 'webapp/App'

const PaymentButton = ({ name, onClick, isLoading }) => {
  const { icon, bankDataKey, editTrans, infoKey, setupTrans } = paymentMethodsDetails[name]
  const userPaymentDetails = !isLoading && user.bankData.value.types[bankDataKey]
  const isDefaultPaymentMethod = user.bankData.value?.default === name

  const setUserDefaultPayment = (withdraw_type) => {
    if (withdraw_type === user.bankData.value.default) return false

    userApi.updateDefaultPaymentMethod({
      withdraw_type,
    })
      .then(() => {
        notification.success({
          message: <Trans>defaultPaymentSaved</Trans>,
        })

        user.bankData.value = {
          ...user.bankData.value,
          default: name,
        }
      })
      .catch((error) => {
        notification.error({
          description: error.message || <Trans>sww</Trans>,
          message: 'Error',
        })
      })
  }

  const editPaymentMethod = (event) => {
    event.stopPropagation()

    onClick('bankAccount', name)
  }

  const buttonChildren = isLoading
    ? <LoadingOutlined />
    : userPaymentDetails
      ? <><span className="trimmedText"><Trans>{editTrans}</Trans> {userPaymentDetails[infoKey]}</span><Icon component={EditOutlined} onClick={editPaymentMethod} /></>
      : <><span className="trimmedText"><Trans>{setupTrans}</Trans></span><Icon component={RightOutlined} /></>

  return (
    <Button
      className={`customBtn ${isDefaultPaymentMethod && 'default-payment-type'} d-flex justify-content-between align-items-center w-100 mb-3`}
      onClick={(e) => userPaymentDetails ? setUserDefaultPayment(name) : editPaymentMethod(e)}
    >
      <Image alt="payment icon" className="icon-list" src={icon} />
      {buttonChildren}
    </Button>
  )
}

PaymentButton.propTypes = {
  dataKey: PropTypes.string,
  editTrans: PropTypes.string,
  icon: PropTypes.object,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  setupTrans: PropTypes.string,
}

export default PaymentButton
