import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { Col, Image } from 'react-bootstrap'
import { LoadingOutlined } from '@ant-design/icons'
import { Trans } from 'react-i18next'
import icons from 'webapp/images/icons'

const { Text, Title } = Typography

const ReturnedItem = ({ country, isRejected, item }) => {
  if (!item)
    return (
      <div className="text-center">
        <LoadingOutlined />
      </div>
    )

  return (
    <Col className="d-flex flex-row returnedItem align-items-start mb-3">
      <Col className="col-3 col-md-1 d-flex justify-content-start">
        <Image alt="bottle" src={item.image_url || icons.Bottle} />
      </Col>
      <Col className="col-6 col-md-10">
        <p>{item.name}</p>
        <Text type="secondary"><Trans>quantity</Trans>: {item.quantity}</Text>
      </Col>

      {!isRejected && (
        <Col className="col-3 col-md-1 d-flex justify-content-end">
          <Title level={5}>
            {new Intl.NumberFormat(country, { currency: item?.price.currency, style: 'currency' }).format(item?.price.value)}
          </Title>
        </Col>
      )}
    </Col>
  )
}

ReturnedItem.propTypes = {
  country: PropTypes.string.isRequired,
  isRejected: PropTypes.bool,
  item: PropTypes.shape({
    image_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    price: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    quantity: PropTypes.number.isRequired,
  }),
}

export default ReturnedItem
