import React from 'react'
import { Button, Col, notification} from 'antd'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import apis from 'webapp/api'
import { Trans } from 'react-i18next'

const ResendValidationCodeBtn = ({ customStyle = 'secondary-button' }) => {
  const navigate = useNavigate()

  const handleResendCode = () => {
    apis.webappInstance.post('/api/v2/user/verification')
      .then(() => {
        notification.success({
          description: <Trans>codeSent</Trans>,
          duration: 5,
          message: <Trans>success</Trans>,
        })
        navigate('/verify-email')
      })
      .catch((error) => {
        notification.error({
          description: <Trans>sww</Trans>,
          duration: 3,
          message: <Trans>error</Trans>,
        })
        console.log(error)
      })
  }

  return (
    <Col className="w-100 d-flex align-item-center justify-content-center">
      <Button className={`${customStyle}`} onClick={handleResendCode}>
        <Trans>resendCode</Trans>
      </Button>
    </Col>
  )}

ResendValidationCodeBtn.propTypes = {
  customStyle: PropTypes.string,
}

export default ResendValidationCodeBtn
