import AustraliaPinActive from './Australia-pin.svg'
import AustraliaPinInactive from './Australia-pin-inactive.svg'
import CanadaPinActive from './Canada-pin.svg'
import CanadaPinInactive from './Canada-pin-inactive.svg'
import FrancePinActive from './France-pin.svg'
import FrancePinInactive from './France-pin-inactive.svg'
import JapanPinActive from './Japan-pin.svg'
import JapanPinInactive from './Japan-pin-inactive.svg'
import UKPinActive from './UK-pin.svg'
import UKPinInactive from './UK-pin-inactive.svg'
import USPinActive from './US-pin.svg'
import USPinInactive from './US-pin-inactive.svg'

export default {
  Australia: { active: AustraliaPinActive, inactive: AustraliaPinInactive },
  Canada: { active: CanadaPinActive, inactive: CanadaPinInactive },
  France: { active: FrancePinActive, inactive: FrancePinInactive },
  Japan: { active: JapanPinActive, inactive: JapanPinInactive },
  UK: { active: UKPinActive, inactive: UKPinInactive },
  US: { active: USPinActive, inactive: USPinInactive },
}
